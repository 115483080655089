<template>
  <q-layout view="lHh Lpr lff">
    <q-header class="bg-dark">
      <q-toolbar>
        <q-btn
          :icon="mdiMenu"
          class="q-mr-sm"
          aria-label="Menu"
          flat
          dense
          square
          @click="navStore.toggle()"
        />

        <q-separator
          vertical
          inset
          dark
        />

        <nuxt-link
          to="/"
          class="flex q-ml-sm"
        >
          <img
            src="/logos/kygunco.svg"
            width="142"
            height="50"
          >
        </nuxt-link>

        <q-space />

        <q-btn
          size="lg"
          color="secondary"
          class="gt-xs text-weight-bold"
          label="(502) 348-3594"
          href="tel:5023483594"
          flat
          square
        />

        <q-btn
          :icon="mdiMagnify"
          padding="sm"
          flat
          dense
          square
          @click="onSearchClick()"
        />

        <q-btn
          :icon="
            authStore.loggedIn ? mdiAccountDetailsOutline : mdiAccountOutline
          "
          padding="sm"
          flat
          dense
          square
          @click="onAccountClick()"
        />

        <q-btn
          :icon="mdiCartOutline"
          :loading="cartStore.pending"
          padding="sm"
          flat
          dense
          square
          @click="cartStore.toggle()"
        >
          <lazy-q-badge
            v-if="cartStore.data?.items?.length"
            floating
            color="red"
            rounded
          />
        </q-btn>
      </q-toolbar>
    </q-header>

    <q-page-container>
      <slot />

      <div class="bg-blue-grey-10 q-pa-lg">
        <div class="row items-center justify-around q-col-gutter-md">
          <div class="col-12 col-sm-auto text-h5 text-weight-regular">
            Learn about new products and upcoming exclusive deals that you won't
            find anywhere else. Sign up to the KYGUNCO newsletter today!
          </div>

          <div class="col-12 col-sm-auto">
            <q-btn
              label="Sign Up"
              size="lg"
              class="full-width"
              color="primary"
              style="min-width: 200px"
              square
              @click="onNewsletterSignUpClick()"
            />
          </div>
        </div>
      </div>

      <div class="bg-blue-grey-10 q-pa-lg">
        <div class="column q-gutter-lg">
          <div>
            <div class="row items-center justify-center q-col-gutter-lg">
              <div class="col-12 col-sm-auto text-h4 text-weight-regular">
                Trust is earned and KYGUNCO is proof of it.
              </div>
            </div>
          </div>

          <div>
            <div class="row items-center justify-around q-gutter-md">
              <div>
                <nuxt-link
                  href="https://www.facebook.com/kentuckygunco/reviews"
                  target="_blank"
                >
                  <q-img
                    src="https://embedsocial.com/api/source_image_badges/svg-badge-1-f/en/facebook/124688"
                    ratio="3.7"
                    width="275px"
                    spinner-color="white"
                  />
                </nuxt-link>
              </div>

              <div>
                <nuxt-link
                  href="https://www.google.com/maps/dir/?q=place_id:ChIJfwecWCBSaIgRguIjuoPha40"
                  target="_blank"
                >
                  <q-img
                    src="https://embedsocial.com/api/source_image_badges/svg-badge-1-g/en/google/1328"
                    ratio="3.7"
                    width="275px"
                    spinner-color="white"
                  />
                </nuxt-link>
              </div>

              <div>
                <nuxt-link
                  href="https://www.bbb.org/us/ky/bardstown/profile/gunsmiths/kentucky-gun-company-0402-7003144"
                  target="_blank"
                >
                  <q-img
                    src="https://m.bbb.org/terminuscontent/dist/img/business-profile/accreditation/AB-seal-horz.svg?tx=f_svg,w_180"
                    ratio="2.85"
                    width="215px"
                    spinner-color="white"
                  />
                </nuxt-link>
              </div>

              <div>
                <q-item>
                  <q-item-section>
                    <q-item-label class="text-h5 text-right">
                      A+
                    </q-item-label>
                    <q-item-label class="text-h6 text-right">
                      Seller
                    </q-item-label>
                  </q-item-section>
                  <q-item-section avatar>
                    <q-img
                      src="/brands/gunbroker.svg"
                      width="100px"
                      ratio="1.75"
                      spinner-color="white"
                    />
                  </q-item-section>
                </q-item>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-dark q-pa-md">
        <div class="row q-col-gutter-md">
          <div class="col-12 col-md-4">
            <FooterCompanyInfo />
          </div>
          <div class="col-12 col-md-3">
            <q-list>
              <q-item to="/blog">
                <q-item-section>
                  <q-item-label class="text-subtitle2">
                    Blog
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item to="/brands">
                <q-item-section>
                  <q-item-label class="text-subtitle2">
                    Brands
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item to="/dealers">
                <q-item-section>
                  <q-item-label class="text-subtitle2">
                    Find Your FFL
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item to="/page/privacy-policy">
                <q-item-section>
                  <q-item-label class="text-subtitle2">
                    Privacy Policy
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item to="/page/terms-and-conditions">
                <q-item-section>
                  <q-item-label class="text-subtitle2">
                    Terms and Conditions
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item to="/page/qualified-professionals">
                <q-item-section>
                  <q-item-label class="text-subtitle2">
                    Qualified Professionals Program
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item to="/contact">
                <q-item-section>
                  <q-item-label class="text-subtitle2">
                    Contact Us
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
          <div class="col-12 col-md-5">
            <FooterContactLinks />
          </div>
        </div>
      </div>
      <div class="flex flex-center bg-primary q-py-sm">
        <small class="text-weight-medium">
          © {{ year }} KYGUNCO. All rights reserved.
        </small>
      </div>
    </q-page-container>

    <NavDrawer />
    <CartDrawer />
    <LazyUserDrawer v-if="authStore.loggedIn && !!userStore.data" />
  </q-layout>
</template>

<script lang="ts" setup>
import {
  mdiMenu,
  mdiCartOutline,
  mdiAccountOutline,
  mdiMagnify,
  mdiAccountDetailsOutline,
} from '@quasar/extras/mdi-v7';
import {
  LazyDialogAuthLoginRegister,
  LazyDialogAuthResetPassword,
  LazyDialogSearch,
  LazyDialogNewsletterSubscribe,
} from '#components';

const route = useRoute();
const cartStore = useCartStore();
const navStore = useNavStore();
const authStore = useAuthStore();
const userStore = useUserStore();
const $q = useQuasar();

const year = useState('year', () => new Date().getFullYear());

const onAccountClick = () => {
  if (!authStore.loggedIn) {
    $q.dialog({
      component: LazyDialogAuthLoginRegister,
    });

    return;
  }

  userStore.toggle();
};

const onNewsletterSignUpClick = () => {
  $q.dialog({
    component: LazyDialogNewsletterSubscribe,
    componentProps: {
      email: userStore.data?.email,
    },
  });
};

const onSearchClick = () =>
  $q.dialog({
    component: LazyDialogSearch,
  });

if (
  route.query.action === 'reset_password'
  && 'email' in route.query
  && 'token' in route.query
) {
  $q.dialog({
    component: LazyDialogAuthResetPassword,
    componentProps: {
      email: route.query.email,
      token: route.query.token,
    },
  });
}
</script>
